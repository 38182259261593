// /src/pages/index.js
import React from "react"
import Layout from "../components/layout"
import Contact from "../components/contact"
import Service from "../components/service"
import HeadLabel from "../components/head-label"
import PostLink from "../components/post-link"
import Button from "../components/button"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

export default function Index({ data }) {
  const edges = data.allContentfulNews.edges

  return (
    <Layout>
      <Seo
        title="TOP | 株式会社STARKS | スタークス"
        ogpSiteName="TOP | 株式会社STARKSコーポレートサイト"
      />

      <div
        id="carouselExampleCaptions"
        className="carousel slide carousel-fade relative"
        data-bs-ride="carousel"
        data-bs-interval="3000"
        data-bs-pause="false"
      >
        <div className="carousel-inner relative w-full overflow-hidden">
          <div className="carousel-item active relative float-left w-full">
            <StaticImage
              src="../images/top01.jpeg"
              className="block w-full"
              alt="コンサルティングイメージ"
            />
            <div className="carousel-caption top-[34%] md:top-[43%] absolute text-center">
              <h1 className="text-2xl md:text-3xl lg:text-4xl animate-fade-in-left font-semibold text-white">
                スターになれる環境が
                <br className="md:hidden" />
                ここにある
              </h1>
            </div>
          </div>
          <div className="carousel-item relative float-left w-full">
            <StaticImage
              src="../images/top02.jpeg"
              className="block w-full"
              alt="美容商材販売イメージ"
            />
            <div className="carousel-caption top-[34%] md:top-[43%] absolute text-center">
              <div className="text-2xl md:text-3xl lg:text-4xl font-semibold text-white">
                スターになれる環境がここにある
              </div>
            </div>
          </div>
          <div className="carousel-item relative float-left w-full">
            <StaticImage
              src="../images/top03.jpeg"
              className="block w-full"
              alt="エステ店舗写真"
            />
            <div className="carousel-caption top-[34%] md:top-[43%] absolute text-center">
              <div className="text-2xl md:text-3xl lg:text-4xl font-semibold text-white">
                スターになれる環境がここにある
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon inline-block bg-no-repeat"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon inline-block bg-no-repeat"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className="container mx-auto">
        <div className="m-5">
          <div className="pt-5 max-w-4xl mx-auto leading-5">
            STARKSはコンサルティング事業、美容商材販売事業、エステサロン事業を中心に、時代の変化に適応した様々なサービス提供を行っています。
          </div>
          <div className="text-right pt-8">
            <span className="border-x border-y border-sky-700 text-sky-700 rounded-full py-3 px-4">
              <FontAwesomeIcon icon={faAngleRight} />
            </span>
            <Link to="/about" className="pl-2">
              STARKSについて
            </Link>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 py-5 my-16">
        <div className="max-w-4xl mx-auto">
          <HeadLabel label={"ニュース"} />

          {edges.map(edge => (
            <PostLink key={edge.node.slug} post={edge.node} />
          ))}
          <Button label={"全て見る"} link={"/news"} external={false} />
        </div>
      </div>

      <div className="container mx-auto">
        <div className="max-w-4xl mx-auto">
          <HeadLabel label={"事業・サービス"} />
          <div className="p-5 mb-5">
            <div className="mx-auto flex flex-wrap justify-center max-w-3xl text-center">
              <Service
                title={"コンサルティング事業"}
                description={[
                  "新規事業企画や事業推進、サービスや店舗のデジタル化、業務DX推進を支援。多業界のクライアントを支援している実績から最適な解決方法を導きます。",
                ]}
                label={"詳しくはこちら"}
                url={"/service/#consul"}
                column={2}
              />

              <Service
                title={"エステサロン事業"}
                description={[
                  "名古屋市中区東別院、岐阜県岐阜市にある美肌専門店「Lavoute(ラボーテ)」を運営。",
                  "いつまでも美しくありたい女性の願いを現代女性のライフスタイルに合わせてサポートします。",
                ]}
                label={"詳しくはこちら"}
                url={"/service/#salon"}
                column={2}
              />

              <Service
                title={"美容室の運営、コンサルティング"}
                description={[
                  "名古屋市中区栄にある美容室「MARQUEE」の運営、美容室の経営コンサル",
                ]}
                label={"詳しくはこちら"}
                url={"https://beauty.hotpepper.jp/slnH000631373/"}
                column={2}
              />

              <Service
                title={"ペットサロンの運営事業"}
                description={[
                  "名古屋市中区にあるペットサロン&HOTEL「MOFUMOFU」の運営",
                ]}
                label={"詳しくはこちら"}
                url={"https://mofumofu.dog/"}
                column={2}
              />

              <Service
                title={"美容商材、検査キットの卸・販売事業"}
                description={[
                  "エステサロン、ホワイトニングサロン向け商材、機器の卸、販売。",
                  "抗原検査キットの卸・販売。",
                ]}
                column={2}
              />

              <Service
                title={"中古車買取・販売事業"}
                description={["輸入車をメインに中古車の買取販売。"]}
                column={2}
              />
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulNews(limit: 3, sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          date(formatString: "YYYY年MM月DD日")
          link
          slug
          createdAt(formatString: "YYYY年MM月DD日")
          metadata {
            tags {
              name
            }
          }
        }
      }
    }
  }
`

// image {
//   title
//   file {
//     url
//   }
//   gatsbyImageData(formats: AUTO, width: 200)
// }
